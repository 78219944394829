import Vue from 'vue'

// echarts 按需加载
import * as echarts from 'echarts/core'
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components'
import { LineChart, PieChart, BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

// 农历
import { getLunar } from 'chinese-lunar-calendar'

import themeColorUtil from '@/utils/theme_color'
import VueLazyComponent from '@xunlei/vue-lazy-component'

// 按需加载 antd 组件
import {
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Cascader,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Descriptions,
  Divider,
  Dropdown,
  Empty,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  Menu,
  message,
  Modal,
  Pagination,
  Popover,
  Radio,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Switch,
  Table,
  Tabs,
  Timeline,
  Tooltip,
  Upload
} from 'ant-design-vue'

Vue.use(Avatar)
Vue.use(Badge)
Vue.use(Breadcrumb)
Vue.use(Button)
Vue.use(Cascader)
Vue.use(Checkbox)
Vue.use(Col)
Vue.use(ConfigProvider)
Vue.use(DatePicker)
Vue.use(Descriptions)
Vue.use(Divider)
Vue.use(Dropdown)
Vue.use(Empty)
Vue.use(Form)
Vue.use(Icon)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Layout)
Vue.use(Menu)
Vue.use(Modal)
Vue.use(Pagination)
Vue.use(Popover)
Vue.use(Radio)
Vue.use(Result)
Vue.use(Row)
Vue.use(Select)
Vue.use(Space)
Vue.use(Spin)
Vue.use(Statistic)
Vue.use(Switch)
Vue.use(Table)
Vue.use(Tabs)
Vue.use(Timeline)
Vue.use(Tooltip)
Vue.use(Upload)

echarts.use(
  [TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    PieChart,
    BarChart,
    CanvasRenderer]
)

let primaryColor = process.env.VUE_APP_PRIMARY_COLOR
// 如果自定义配色存在则使用自定义配色
if (window.customConfig.primaryColor) {
  primaryColor = window.customConfig.primaryColor
}

// 设置 $primaryColor
Vue.prototype.$primaryColor = primaryColor
// 对于双色图标，可以通过使用 getTwoToneColor() 和 setTwoToneColor(colorString) 来全局设置图标主色。
Icon.setTwoToneColor(primaryColor)

// 如果默认配色与自定义配色不同, 则切换主题
if (process.env.VUE_APP_PRIMARY_COLOR !== primaryColor) {
  themeColorUtil.changeColor(primaryColor)
}

Vue.prototype.$lunar = getLunar
Vue.prototype.$echarts = echarts
Vue.prototype.$message = message
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
Vue.prototype.$success = Modal.success
Vue.prototype.$confirm = Modal.confirm

Vue.use(VueLazyComponent)
