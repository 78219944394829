<template>
  <a-layout-content>
    <router-view />
  </a-layout-content>
</template>

<script>

export default {
  name: 'LoginContentWrapper'
}
</script>

