<template>
  <div class="bg">
    <div
      class="resize-base"
      ref="resizeBase"
    >
      <div
        class="resize-sensor"
        ref="resizeSensor"
        :style="{ width: `${this.resizeSensorWidth}px`, height: `${this.resizeSensorHeight}px` }"
      >
        <div
          class="dashboard-container"
          ref="dashboardContainer"
          :style="{
            width: `${this.dashboardContainerDesignWidth}px`,
            height: `${this.dashboardContainerDesignHeight}px`,
            transform: this.dashboardContainerScale
          }"
        >
          <a-layout class="layout">
            <layout-header />
            <layout-content />
          </a-layout>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutHeader from '@/components/LayoutHeader/Home'
import LayoutContent from '@/components/LayoutContent/CustomStyle'

export default {
  name: 'LargeScreenLayout',
  components: {
    LayoutHeader,
    LayoutContent
  },
  data() {
    return {
      resizeSensorWidth: 0,
      resizeSensorHeight: 0,
      dashboardContainerDesignWidth: 1920, // 按照 1920 * 1080 设计, 最终按照比例缩放
      dashboardContainerDesignHeight: 1080
    }
  },
  mounted() {
    window.addEventListener('resize', this.setLargeScreenSize)
    window.dispatchEvent(new Event('resize'))
  },
  destroyed() {
    window.removeEventListener('resize', this.setLargeScreenSize)
  },
  computed: {
    // 内部部分根据设计的比例缩放
    dashboardContainerScale() {
      if (this.resizeSensorWidth) {
        return `scale(${this.resizeSensorWidth / this.dashboardContainerDesignWidth}, ${this.resizeSensorHeight / this.dashboardContainerDesignHeight})`
      } else {
        return ''
      }
    }
  },
  methods: {
    setLargeScreenSize() {
      const baseHeight = this.$refs.resizeBase.clientHeight
      const baseWidth = this.$refs.resizeBase.clientWidth
      const ratio = 0.5625 // 16 : 9 屏幕的高宽比 height / width = ratio

      this.resizeSensorHeight = baseHeight
      this.resizeSensorWidth = baseWidth

      if (baseWidth * ratio > baseHeight) {
        // 宽屏
        this.resizeSensorWidth = baseHeight / ratio
      } else {
        // 窄屏
        this.resizeSensorHeight = baseWidth * ratio
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/styles/variables";

// large screen 相关
.bg {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  font-size: 20px;

  .resize-base {
    position: relative;
    width: 100%;
    height: 100%;

    .resize-sensor {
      position: absolute;
      inset: 0;
      overflow: hidden;
      margin: 0 auto;

      .dashboard-container {
        position: relative;
        overflow: hidden;
        box-shadow: 0 0 10px 0 rgba(0 0 0 / 50%);
        transition: all 0.3s linear;
        transform-origin: 0 0;
      }
    }
  }
}

// 内部 layout 相关
.layout {
  height: 100%;

  .section {
    padding: @section-padding;
  }
}
</style>
