<template>
  <div class="box custom-height-100p">
    <component
      :is="currentComponent"
    />
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    DressScreen: () => import('@/views/home/dress_screen/index'),
    DeliveryScreen: () => import('@/views/home/delivery_screen/index'),
    DispatchScreen: () => import('@/views/home/dispatch_screen/index'),
    ComprehensiveScreen: () => import('@/views/home/comprehensive_screen/index')
  },
  computed: {
    currentComponent() {
      switch (this.$store.getters.screenType) {
        case 'dress_screen':
          return 'DressScreen'
        case 'delivery_screen':
          return 'DeliveryScreen'
        case 'dispatch_screen':
          return 'DispatchScreen'
        case 'comprehensive_screen':
          return 'ComprehensiveScreen'
        default:
          return ''
      }
    }
  }
}
</script>
