import Home from '@/views/home'
// import HomeLayout from '@/layouts/HomeStyleLayout'
import HomeLargeScreen from '@/layouts/HomeLargeScreen'
import LoginLargeScreen from '@/layouts/LoginLargeScreen'

// 基础路由(不受权限控制的，所有角色都有的路由)
export const constantRouter = [
  {
    path: '/login',
    component: LoginLargeScreen,
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('@/views/login/index.vue'),
        meta: { title: '欢迎登录' }
      }
    ]
  },
  {
    path: '/',
    component: HomeLargeScreen,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: { menu: 'home' }
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/exception/404')
  }
]
