<template>
  <a-layout-header class="header" :style="{backgroundColor: backgroundColor + '!important'}">
    <a-row
      :gutter="25"
      class="custom-height-100p custom-flex custom-items-center"
    >
      <a-col
        :span="12"
        class="custom-height-100p custom-flex custom-items-center"
      >
        <a-space @click="showValidPassword" style="cursor: pointer;">
          <div class="logo custom-flex custom-items-center custom-justify-center">
            <img :src="logoUrl" alt="logo">
          </div>
          <h1 class="custom-margin-0 custom-ellipsis name">{{ appName }}</h1>
        </a-space>
      </a-col>

      <a-col
        :span="12"
        class="custom-height-100p custom-flex custom-justify-end custom-items-center"
      >
        <div class="custom-flex custom-justify-end custom-items-center back" @click="showValidPassword">
          <a-space>
            <img src="/temp/back.png" alt="logo" class="back-logo">
            <span>返回</span>
          </a-space>
        </div>
      </a-col>
    </a-row>

    <valid-password
      v-if="isShowValidPasswordModal"
      :visible.sync="isShowValidPasswordModal"
      @completed="handleToHome"
    />
  </a-layout-header>
</template>

<script>

export default {
  name: 'HeaderNavbar',
  components: {
    ValidPassword: () => import('@/views/home/ValidPassword')
  },
  data() {
    return {
      isShowValidPasswordModal: false
    }
  },
  computed: {
    logoUrl() {
      return window.customConfig.homeLogoUrl
    },

    appName() {
      return window.customConfig.name
    },

    backgroundColor() {
      return window.customConfig.bgColor
    }
  },
  methods: {
    handleToHome() {
      this.$router.push('/')
    },

    showValidPassword() {
      this.isShowValidPasswordModal = true
    },

    handleLogout() {
      return this.$store.dispatch('Logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  padding: 0 20px;
}

.logo {
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;

  img {
    height: 40px;
  }
}

.name {
  color: @white;
}

.back {
  img {
    width: 20px;
  }

  span {
    color: @white;
  }
}

.user-full-name {
  max-width: 200px;
  font-size: 20px;
  color: @white;
}
</style>
