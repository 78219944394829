const getters = {
  userId: state => state.user.id,
  userEmpName: state => state.user.empName,
  userRole: state => state.user.role,
  permissions: state => state.user.permissions,
  avatar: state => state.user.avatar,
  funeralHomeName: state => state.user.funeralHomeName,
  screenType: state => state.user.screenType,
  dynamicBreadCrumbs: state => state.app.dynamicBreadCrumbs,
  currentMenus: state => state.app.currentMenus
}

export default getters
