<template>
  <a-layout-header class="header" :style="{backgroundColor: backgroundColor + '!important'}">
    <a-row
      :gutter="25"
      class="custom-height-100p custom-flex custom-items-center"
    >
      <a-col
        :span="12"
        class="custom-height-100p custom-flex custom-items-center"
      >
        <a-space>
          <div class="logo custom-flex custom-items-center custom-justify-center">
            <img :src="logoUrl" alt="logo">
          </div>
          <h1 class="custom-margin-0 custom-ellipsis name">安孝堂智慧生命服务</h1>
        </a-space>
      </a-col>

      <a-col
        :span="12"
        class="custom-height-100p custom-flex custom-justify-end custom-items-center"
      >
        <div style="margin-right: 10px">
          <a-space>
            <a-icon v-if="!isScreenFull" @click="onScreenFullClick" type="fullscreen" :style="{ fontSize: '20px' }" title="开全屏" />
            <a-icon v-else @click="onScreenFullClick" type="fullscreen-exit" :style="{ fontSize: '20px' }" title="关全屏" />
          </a-space>
        </div>

        <div class="custom-flex custom-justify-end custom-items-center custom-primary-color">
          <div class="user-info-wrapper">
            <a-dropdown placement="bottomRight">
              <a-space class="custom-cursor-pointer">
                <img v-if="$store.getters.avatar" :src="$store.getters.avatar" class="avatar">
                <a-avatar v-else icon="user" />
                <div class="user-full-name">
                  {{ this.$store.getters.userEmpName }}
                </div>
              </a-space>
              <a-menu slot="overlay">
                <a-menu-item
                  key="logout"
                  @click="handleLogout"
                >
                  <a-space>
                    <a-icon type="logout" />
                    退出系统
                  </a-space>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-layout-header>
</template>

<script>
import screenfull from 'screenfull'
export default {
  name: 'HomeHeader',
  data() {
    return {
      showingNotificationId: 0, // 显示的通知消息id
      notificationTimer: null,
      isScreenFull: false
    }
  },
  computed: {
    logoUrl() {
      return window.customConfig.homeLogoUrl
    },

    appName() {
      return window.customConfig.name
    },

    backgroundColor() {
      return window.customConfig.bgColor
    }
  },
  destroyed() {
    clearInterval(this.notificationTimer)
  },
  methods: {
    handleLogout() {
      return this.$store.dispatch('Logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    },
    onScreenFullClick() {
      if (this.isScreenFull) {
        screenfull.exit()
        this.isScreenFull = false
      } else {
        screenfull.request()
        this.isScreenFull = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  padding: 0 20px;
}

.logo {
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;

  img {
    height: 40px;
  }
}

.name {
  color: @white;
}

.avatar {
  height: 40px;
  border-radius: 20px;
}

.user-full-name {
  max-width: 200px;
  font-size: 20px;
  color: @white;
}
</style>
